.userDashContainer{
  padding: 0px 50px;
}
.userptscontainer
{
  position:sticky;
  top: 10px;
}
.u_title{
  font-family: "Syne";
  font-weight: 700;
  font-size: 5.375rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 92px;
}
.u_subtitle{
  font-family: "Manrope";
  font-weight: 400;
  font-size: 1.938rem;
  text-transform: capitalize;
  line-height: 2.647rem;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;
}

.videos{
  display: flex;
  flex-direction: column;
  margin-bottom: 65px;
  padding:0px 50px ;
  cursor: pointer;
  width: fit-content;
}
.video_cover{
  height: 280px;
  width: 594px;
}
.video_icon{
  
  height: 66px;
  width: 66px;
  margin-top: 10px;
}
.video_downdiv{
  display: flex;
  margin-top: 20px;
  gap: 35px;
}
.views{
  display: flex;
}
.video_title{
  font-family: "Manrope";
  font-size: 1.563rem;
  line-height: 2.134rem;
  font-weight: 600;
  max-width:500px;
}
.video_author{
  font-family: "Manrope";
  font-size: 1.438rem;
  line-height: 1.964rem;
  font-weight: 600;
  margin-top: 11px;
  color: #626161;
}
.video_views,
.video_year{
  font-family: "Manrope";
  font-size: 1.438rem;
  line-height: 1.964rem;
  font-weight: 600;
  
  color: #626161;
}

@media (max-width:1024px){
  .userptscontainer
    {
      position:static;
      top: 10px;
    }
  .u_title{
    font-size: 4.375rem;
    margin-top: 62px;
  }
  .userDashContainer{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
}


@media (max-width:852px){
  .u_title{
    
    font-size: 3.375rem;
    
    margin-top: 42px;
  }
  .u_subtitle{
    
    margin-bottom: 100px;
  }

}
@media (max-width:790px){
  .video_cover{
    height: 250px;
    width: 494px;
  }
  .video_icon{
    height: 56px;
    width: 56px;
  }
  .video_title{
    font-size: 1.463rem;
  }
  .video_author{
    font-size: 1.338rem;
  }
  .video_views,
  .video_year{
    font-size: 1.338rem;
  }
}

@media (max-width:690px){
 
  .video-size
{
  width: 500px;
  height: 290px;
}
  .u_title{
    font-size: 3rem;
    margin-top: 42px;
  }
  .u_subtitle{
    font-family: "Manrope";
    font-weight: 400;
    font-size: 1.8rem;
    text-transform: capitalize;
    line-height: 1.547rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .videos{
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    padding:0px 50px ;
    cursor: pointer;
    width: fit-content;
    
    align-items: center;
  }
  .video_cover{
    height: 200px;
    width: 394px;
  }
  .video_icon{
    
    height: 46px;
    width: 46px;
  }
  
  
  .video_title{
    font-family: "Manrope";
    font-size: 1.263rem;
    line-height: 2.134rem;
    font-weight: 600;
  }
  .video_author{
    
    font-size: 1.138rem;
    
    margin-top: 2px;
    
  }
  .video_views,
  .video_year{
    font-family: "Manrope";
    font-size: 1.238rem;
    line-height: 1.964rem;
    font-weight: 600;
    
    color: #626161;
  }
  
}
@media (max-width:616px){
  .u_title{
   
    font-size: 2.5rem;
    
    
  }
  .u_subtitle{
    
    font-size: 1.4rem;
    
    margin-bottom: 100px;
  }
}
@media (max-width:530px){
  .ytp-cued-thumbnail-overlay
  {
    width: 300px !important;
  }
  .video-size
  {
    width: 300px;
    height: auto;
  }
  .u_title{
    font-size: 1.5rem;
  }
  .userDashContainer{
    padding: 0px 0px;

    
  }
  .u_subtitle{
    font-size: 0.875rem;
    margin-bottom: 60px;
  }
  .videos{
    align-items: center;
    margin-bottom: 65px;
    padding:0px 0px ;
    width: 300px;
  }
  .video_cover{
    /* height: 161px; */
    width: 274px;
  }
  .video_icon{
    
    height: 26px;
    width: 26px;
  }
  
  
  .video_title{
    font-family: "Manrope";
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;
  }
  .video_downdiv{
    display: flex;
    margin-top: 20px;
    gap: 16px;
  }
  .video_author{
    
    font-size: 1.138rem;
    
    margin-top: 2px;
    
  }
  .video_views,
  .video_year{
    font-family: "Manrope";
    font-size: 1.238rem;
    line-height: 1.964rem;
    font-weight: 600;
    
    color: #626161;
  }
}

#error-div
{
  margin-left: 50px;
  font-size: 20px;
  width: fit-content;
  font-weight: bold;
  color: red;
}
#error-div button
{
  padding: 5px;
  margin: 0 auto;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 5px;
  margin-top: 10px;
}