.reachUs-container{
display:flex ;
justify-content: space-between;
padding: 78px 210px;
}
.adTitle{
  font-family: Manrope;
font-size: 24.85px;
font-weight: 400;
line-height: 2.5rem;
text-align: left;
padding:10px 0px 10px 0px;
}
.adTitlespl{
  font-family: Manrope;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 28.68px;
  text-align: left;
  padding:10px 0px 10px 0px;
}
.reachus{
  font-family: Syne;
font-size: 7.563rem;
font-weight: 700;
line-height: 145.56px;
text-align: right;
text-transform: uppercase;
}
.reachus_span{
  font-family: Manrope;
  font-size: 7.563rem;
  font-weight: 700;
  line-height: 145.56px;
  text-align: right;
  text-transform: uppercase;
}
@media (max-width:1132px){
  .reachus,
  .reachus_span{
    
  font-size: 5.563rem;
  line-height: 100.56px;
  }

}
@media (max-width:990px){
  .reachus,
  .reachus_span{
    
  font-size: 4.563rem;
  line-height: 100.56px;
  }
  .adTitle{
    
  font-size: 1.2rem;
 
  line-height: 28.68px;
  
 
  }
  .adTitlespl{
    font-family: Manrope;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 28.68px;
    text-align: left;
    padding:10px 0px 10px 0px;
    font-weight: 700;
  }
}
@media (max-width:800px){
  .reachus,
  .reachus_span{
    
  font-size: 3.563rem;
  line-height: 100.56px;
  }
  .reachUs-container{
    
    padding: 27px;
    /* gap:50px; */
   
    }
}
@media (max-width:800px){
  .reachus,
  .reachus_span{
    
  font-size: 3.563rem;
  line-height: 100.56px;
  }
  .reachUs-container{
    
    padding: 40px;
    /* gap:50px; */
   
    }
}
@media (max-width:654px){
  .reachus,
  .reachus_span{
    
  font-size: 2.563rem;
  line-height: 40.56px;
  }
  .reachUs-container{
    
    padding: 40px;
    /* gap:50px; */
   
    }
    .adTitle ad{
      color: aqua;
    }
}

@media (max-width:580px){
  .reachus,
  .reachus_span{
    
  font-size: 1.75rem;
  line-height: 40.56px;
  }
  .adTitle{
    font-family: Manrope;
  font-size: 0.75rem;
  font-weight: 400;
  /* line-height: 28.68px; */
  text-align: left;
  padding:10px 0px 10px 0px;
  }
  
}