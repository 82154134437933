.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 50px;
  justify-content: space-between;
}
.logo {
  width: 72px;
  height: 90.355px;
}
.login-btn {
  font-family: "Manrope";
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #efd5ff;
  padding-top: 13px;
  padding-right: 13px;
}
.signup-btn {
  font-family: "Manrope";
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 15px;
  border: 2px solid #efd5ff;
  color: #efd5ff;
  width: 164px;
  height: 53px;
  padding: 13px;
  position: relative;
  text-align: center;
}
.links {
  display: flex;
  /* width: 258px; */
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
}
@media (max-width:484px){
  .logo {
    width: 32px;
    height: 40px;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 16px;
    justify-content: space-between;
  }
  .login-btn {
    font-family: "Manrope";
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: 500;
    color: #efd5ff;
    padding-top: 10px;
    padding-right: 13px;
  }
  .signup-btn {
    font-family: "Manrope";
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 12px;
    border: 2px solid #efd5ff;
    color: #efd5ff;
    width: 86px;
    height: 38px;
    padding: 8px;
    position: relative;
    text-align: center;
  }
}