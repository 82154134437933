.mob_signup_container {
  background-image: url(../../logos/mobilebg.jpg);
  background-color: black;
  width: auto;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}
.mob_signup_title_cont {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fafafa;
}
.mob_signup_title {
  font-family: Syne;
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 80px;
  text-transform: uppercase;
}
.mob_signup_subtitle {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 40px;
}
.mob_signup_div {
  display: flex;
  flex-direction: column;
}
.mob_signup_line {
  border-top: 1px solid #ffffff;
  margin-top: 42px;
}

.signup_username_inp_box {
  height: 60px;
  width: 328px;
  font-family: Manrope;
  padding: 12px 16px;
}

.mob_signup_div .signup_username_inp_box {
  height: 60px;
  width: 328px;
  font-family: "Manrope";
  padding: 12px 16px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  background-color: transparent;
}
.signup_username_inp_box::placeholder {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #ffffff;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.mob_createacc_now {
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 500;
  line-height: 11.66px;
  text-align: center;
  padding-top: 15px;
  margin-top: 30px;

  color: #fafafa;
  cursor: pointer;
  border-radius: 12px;
  background: transparent;
  border: 1px solid #ffffff;
}
.gbtn {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 12px 16px;
  gap: 80px;
}
.g_icon {
  height: 23px;
  width: 23px;
}
.mob_signup_haveacc {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  /* padding-bottom: 40px; */
}


.mob_signup_haveacc-forgetpass {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  margin-top: 20px;
  text-align: right;
  margin-right: 10px;
  color: #ffffff;
  /* padding-bottom: 40px; */
}
