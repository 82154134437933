.userNav{
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.udashleft{
  display: flex;
  align-items: center;
  gap: 12px;
}
.usernavlogo{
  height: 96px;
  width: 96px;
}
.userprofname{
  font-family: Syne;
}

@media(max-width:530px){
  .usernavlogo{
    height: 46px;
    width: 46px;
  }
  .userprofile{
    height: 23px;
  width: 23px;
  }
.userprofname{
  font-size: 10px;
}
}
.logout {

  background-color: #121212;
  color: white;
  border-radius: 5px;
  float: right;
  padding: 5px;
  cursor: pointer;

}

.log{
  padding: 1px;
}

.userNav:hover .logout {
  display: block;
}
@media (max-width: 530px) {
  .logout {
     width: 30px;
     
  }
  .fontawsome{
    margin-left: 3px !important;
  }
  .log{
    display: none ;
  }
}