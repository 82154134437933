.search-wrapper {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px; 
  /* Adjust based on the width of the button */
}
.ownerdashnav{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ownerdashtitle{
  font-family: Manrope;
  font-size: 2rem;
  font-weight: 500;
}


.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 20px;
  margin-bottom: 10px;
}

.icon {
  color: #333; /* Adjust icon color */
  margin-right: 10px; /* Adjust icon spacing */
}
.wrapper{
  display: flex;
  justify-content: space-between;

}
.total_user_circle {
  width: 191px;
  height: 191px;
  border-radius: 50%;
  background-color: #DDDDDD; 
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}
.total_user_content{
  text-align: center;
  font-family: Manrope;
font-size: 20px;
font-weight: 500;
line-height: 32.78px;


}



.table-container {
  margin: 0 auto; /* Auto margins horizontally center the container */
  width: fit-content; /* Make the container width fit its content */
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 500;
  line-height: 27.32px;
  text-align: left;
  border-radius: 10px 10px ;
  height: 385px;
  min-width: 1020px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}


.styled-table thead tr {
  background-color:  #DDDDDD;
  
  color: #000;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #000;
}

.styled-table tbody tr:last-child {
  border-bottom: none; /* Remove bottom border for the last row */
}
.styled-table button {
  background: none; 
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  font: inherit; /* Inherit font */
  cursor: pointer; /* Set cursor to pointer */
  box-sizing: content-box;
}
.styled-table td button {
  padding:10px ; 
}



.redeembutton {
  background-color: #202020 !important; /* Set black background */
  color: white; /* Set text color to white */
  border: none; /* Remove border */
  /* Add padding */
  cursor: pointer; /* Set cursor to pointer */
  border-radius: 10px;
  padding: 12px 16px !important;
  font-size: Manrope !important;
  
}


