.earnptscontainer {
  background-color: #fafafa;
  height: auto;
  width: 100%;
  padding: 150px 50px;
}
.subdiv{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  justify-content: space-around;
  /* align-items: center; */
}
.earnflex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 90px;
  /* gap: 113px; */
  justify-content:space-between;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
}
.earnsteps {
  display: flex;
  flex-direction: column;
  
  /* width: 547px; */
}
.earnpageimage {
  border-radius: 10px;
}
.maintext {
  color: #000;
  font-family: "Syne";
  font-size: 3.828rem;
  font-style: normal;
  max-width: fit-content;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  border-top: 6px solid #000;
}

.steptitle {
  color: #000;
  font-family: Manrope;
  font-size: 1.953rem;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  text-transform: uppercase;
  padding: 10px 0;
}
.step-para {
  color: #000;
  font-family: Manrope;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  /* width: 547px;
  height: 138px; */
  padding: 10px 0;
  margin-bottom: 45px;
  text-transform: capitalize;
  word-wrap: normal;
}
/*---------------------------------------------------------------------------------*/
@media (max-width:1340px){
  .earnpageimage {
    border-radius: 10px;
    margin-top: 12px;
    width: 600px;
    height: 560px;
  }
  .steptitle {
    font-size: 1.953rem;
    font-weight: 700;
    line-height: 46px;
    padding: 10px 0;
  }
  .step-para {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 46px;
    padding: 10px 0;
    margin-bottom: 30px;
  }
}
@media (max-width:1275px){
  .earnpageimage {
    border-radius: 10px;
    width: 540px;
    margin-top: 25px;
    height: 500px;
  }
  .steptitle {
    font-size: 1.953rem;
    font-weight: 700;
    line-height: 46px;
    padding: 10px 0;
  }
  .step-para {
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 46px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
}
@media (max-width:1200px){
  .maintext {
    font-size: 3.4rem;
    font-weight: 700;
    border-top: 6px solid #000;
  }
  .earnpageimage {
    border-radius: 10px;
    width: 500px;
    margin-top: 35px;
    height: 460px;
  }
  .steptitle {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 46px;
    padding: 10px 0;
  }
  .step-para {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 46px;
    padding: 10px 0;
    margin-bottom: 5px;
  }
}
@media (max-width:1024px){
  .maintext {
    font-size: 3rem;
    font-weight: 700;
    border-top: 6px solid #000;
  }
  .earnpageimage {
    border-radius: 10px;
    width: 327px;
  margin-top: 0px;
    height: 300px;
  }
  .steptitle {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 25px;
    padding: 1px 0;
  }
  .step-para {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 35px;
    padding: 3px 0;
    margin-bottom: 5px;
  }
  
}
@media(max-width:876px){
  .maintext {
    font-size: 2.7rem;
    font-weight: 700;
    border-top: 6px solid #000;
  }
  .earnflex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;
    /* gap: 113px; */
    justify-content:space-between;
    /* align-items: center; */
    justify-content: center;
    height: auto;
  }
}
@media(max-width:836px){
  .earnpageimage {
    border-radius: 10px;
    width: 327px;
  margin-bottom: 60px;
    height: 300px;
  }
  .earnflex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    /* gap: 113px; */
    /* justify-content:space-between; */
    /* align-items: center; */
    justify-content: center;
    height: auto;
  }
  .step-para {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 35px;
    padding: 3px 0;
    margin-bottom: 5px;
  }
  
}
@media(max-width:511px){
  
  .step-para {
    font-size: 1rem;
    font-weight: 400;
    line-height: 35px;
    padding: 3px 0;
    margin-bottom: 5px;
  }
  .maintext {
    font-size: 1.7rem;
    color: #202020;
   
    font-weight: 700;
    line-height: 36px;
    border-top: 4px solid #000;
  }
  .steptitle {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 22px;
    padding: 1px 0;
  }
  
}
@media(max-width:467px){
  .earnptscontainer {
    padding: 60px 16px;
    height: 916px;
  }
  .maintext {
    font-size: 1.563rem;
    color: #202020;
    font-weight: 700;
    line-height: 36px;
    border-top: 3px solid #000;
    max-width: 300px;
  }
}