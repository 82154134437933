.container {
  background-image: url(../../../public/backgroundnew.jpg);
  /* width: 100%; */
  height: 1011px;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  /* aspect-ratio: 16 / 9; */
  background-repeat: no-repeat;
}
.main-flex {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding: 0px 50px;
  padding-bottom: 97px;
}

.main-text {
  font-family: "Syne";
  color: #efd5ff;
  max-width: 1300px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  font-size: 5.3rem;
}

.main-text-span {
  border-bottom: 7px solid #efd5ff;
}

.sub-text {
  color: #efd5ff;
  /* text-align: justify; */
  font-family: "Manrope";
  font-size: 1.563rem;
  font-weight: 300;
  max-width: 673px;
  line-height: 30px;
  margin-top: 64px;
}
.takeMe-btn {
  border-radius: 15px;
  display: flex;
  padding: 9.383px 9px 9.383px 9.383px;
  border: 2px solid #efd5ff;
  font-family: "Manrope";
  color: #efd5ff;
  align-items: center;
  margin-top: 64px;
  justify-content: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 23.457px;
  text-decoration: none;
  width: 178.272px;
  height: 53.482px;
  line-height: 23.457px;
}

@media (max-width: 1373px) {
  .main-text {
    font-size: 4.7rem;
  }
}

@media (max-width: 1228px) {
  .main-text {
    font-size: 4.1rem;
  }
}
@media (max-width: 1084px) {
  .main-text {
    font-size: 3.5rem;
  }
}
@media (max-width: 940px) {
  .main-text {
    font-size: 2.9rem;
  }
}
@media (max-width: 796px) {
  .main-text {
    font-size: 2.3rem;
  }
  .main-text-span {
    border-bottom: 5px solid #efd5ff;
  }
  .sub-text {
    font-size: 1.2rem;
    line-height: 30px;
    margin-top: 25px;
  }
}
@media (max-width: 653px) {
  .main-text {
    font-size: 2rem;
  }
  .main-text-span {
    border-bottom: 4px solid #efd5ff;
  }
}
@media (max-width: 580px) {
  .main-text {
    font-size: 1.7rem;
  }
  .main-text-span {
    border-bottom: 3px solid #efd5ff;
  }
}
@media (max-width: 508px) {
  .main-text {
    font-size: 1.6rem;
  }
}

@media (max-width: 484px) {
  .main-text {
    line-height: 30px;
    font-size: 1.563rem;
  }

  .main-flex {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    padding: 0px 16px;
    padding-bottom: 56px;
  }
  .main-text {
    font-size: 1.5rem;
  }
  .sub-text {
    font-size: 0.875rem;
    line-height: 20px;
  }
  .main-flex {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0px 16px;
    padding-bottom: 56px;
  }
  .container {
    background-image: url(../../../public/backgroundnew.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 515px;
  }
  .takeMe-btn {
    border-radius: 12px;
    display: flex;
    padding: 9.383px 9px 9.383px 9.383px;
    border: 2px solid #efd5ff;
    font-family: "Manrope";
    color: #efd5ff;
    align-items: center;
    margin-top: 35px;
    justify-content: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 23.457px;
    text-decoration: none;
    width: 118.9px;
    height: 38px;
    line-height: 23.457px;
  }
}
