.branding-container {
  /* min-height: 1007px; */
  background-color: #fafafa;
  width: 100%;
  
  padding: 190px 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* justify-content: space-between; */
  gap: 100px ;
}

.branding-title {
  color: #000;
  
  font-family: "Syne";
  font-size: 3.828rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  /* position: relative; */
  border-top: 6px solid #000;
  
}
/* .branding-title::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 181px;
  left: 0;
  height: 7px;
  background: black;
} */
.branding-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  
  
  /* gap: 130px; */
}
.branding-flex-left {
  display: flex;
  justify-content: space-between;
  width: 563px;
  flex-direction: column;
  gap: 40px;
}
.branding-flex-right {
  display: flex;
  width: 563px;
  /* justify-content: space-between; */
  gap: 40px;
  flex-direction: column;
}
.branding-flex-words {
  color: #000;

  font-family: Manrope;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  text-transform: capitalize;
}

@media (max-width:1269px){
  
  .branding-flex-left {
    width: 453px;
    gap: 40px;
    /* justify-content: space-between; */
  }
  .branding-flex-right {
    width: 453px;
    gap: 40px;
    /* justify-content: space-between; */
  }
}
@media (max-width:1042px){
  .branding-flex-words {
    font-size: 1.3rem;
    
  }
  .branding-flex-left {
    width: 403px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex-right {
    width: 403px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-title {
    font-family: "Syne";
    font-size: 3rem;
  }
}
@media (max-width:955px){
  .branding-flex-words {
    font-size: 1.3rem;
    
  }
  .branding-flex-left {
    width: 363px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex-right {
    width: 363px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-title {
    font-family: "Syne";
    font-size: 3rem;
  }
}

@media(max-width:869px) {
  .branding-flex-left {
    width: 363px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex-right {
    width: 363px;
    /* justify-content: space-between; */
    gap: 40px;
  }
}
@media(max-width:855px) {
  .branding-flex-left {
    width: 343px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex-right {
    width: 343px;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex {
   
    gap: 51px;
  }
}
@media(max-width:836px) {
  .branding-container {
    padding: 100px 70px;
    gap: 40px;
  }
  .branding-flex-left {
    width: auto;
    /* justify-content: space-between; */
    /* align-items: center; */
    gap: 40px;
  }
  .branding-flex-right {
    width: auto;
    /* justify-content: space-between; */
    gap: 40px;
  }
  .branding-flex {
    justify-content: center;
    
  }
  .branding-title {
    
    font-size: 2.5rem;
  }
  
}
@media(max-width:460px) {
  .branding-title {
    font-size: 2rem;
    border-top: 4px solid #000;
  } 
  .branding-flex-words {
    font-size: 1rem;
    
  }
  .branding-flex-words {
    font-size: 0.875rem;
    line-height: 30px;
    
  }
  .branding-container {
    padding: 60px 16px;
    gap: 30px;
    height: fit-content;
  }
}
@media(max-width:388px) {
  .branding-title {
    font-size: 1.5rem;
    border-top: 2px solid #000;
  }
  .branding-flex-words {
    font-size: 0.875rem;
    line-height: 22px;
    
  }
  
}