.login_container {
  background-image: url(../../../public/backgroundnew.jpg);
  width: auto;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.center_login_container {
  width: 900px;
  height: 550px;
  background-color: #fafafa;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
}
.login_left_section {
  padding: 42px 21px;
  display: flex;
  flex-direction: column;
}
.login_welcome {
  font-family: Syne;
  font-weight: 700;
  font-size: 2.625rem;
  margin-bottom: 15px;
}
.login_form {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.username_label {
  font-family: Manrope;
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 4px;
  margin-top: 5px;
}
.username_inp_box {
  height: 45px;
  width: 350px;
  font-family: Manrope;
  padding: 20px 10px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  height: 45px;
  width: 350px;
  font-family: "Manrope";
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  outline: none;
}


input[type="text"]:focus,
input[type="password"]:focus {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.register_now {
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 500;
  line-height: 11.66px;
  text-align: center;
  padding-top: 15px;
  margin-top: 10px;
  background-color: #000;
  color: #fafafa;
  cursor: pointer;
  border-radius: 5px;
}

.or_signup_with {
  margin-top: 30px;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 10.5px;
  text-align: left;
}

.google_btn {
  background-color: #fafafa;
  width: 120px;
  height: 40px;
  margin-top: 25px;

  border-radius: 10px;
  border: 1px #aaaaaa solid;
  padding: 13px 7px;
  cursor: pointer;
  position: relative;
}
.google_word {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
}
.google_icon {
  height: 15px;
  width: auto;
  text-align: center;
}
.google_word {
  text-align: center;
}

.sideframeimg {
  width: 454px;
  height: 550px;
}

@media (max-width: 910px) {
  .center_login_container {
    width: 700px;
    height: 550px;
    background-color: #fafafa;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
  }
  .sideframeimg {
    height: 554px;
    width: 370px;
  }
  .username_inp_box {
    height: 45px;
    width: 270px;
    font-family: Manrope;
    padding: 20px 10px;
  }
  input[type="text"],
input[type="password"] {
  height: 45px;
  width: 270px;
  font-family: "Manrope";
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  outline: none;
}
}


.loading{
  width: 30px;
  height: 30px;
  visibility: hidden;
  border-radius: 50%;
  border: 3px solid #eaf0f600;
  border-top: 3px solid #000000;
  border-right: 3px solid #000000;
  border-bottom: 3px solid #000000;
  display: inline-block;
  margin-left: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); } 
  100% { transform: rotate(360deg); }
}
#eye_button{
position: absolute;
 right: 10px;
top: 30%;
margin-top: 5px;
transform: translateY(-50%);
border: none;
background: none;
cursor: pointer;
outline: none;
color: gray;
}

.mob_signup_haveacc-desk{
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    margin-top: 20px;
    text-align: center;
    color: #000000;
}

/******************************************************************/
