.user_points_box{
  display: flex;
  gap: 2px;
  float: right;
  padding-bottom:46px ;
}

.left_box{
  height: auto;
  width: 340px;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 89px 23px;
  text-decoration: underline;
  /* line-height: 27.32px; */
  color: #FAFAFA;
  background-color: #202020;
  border-radius: 15px 0px 0px 15px;
}
.right_box{
  height: auto;
  width: 93px;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 89px 23px;
  color: #FAFAFA;
  background-color: #202020;
  border-radius: 0px 15px 15px 0px;
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
}
.star{
  height:24px ;
  width: 24px;
  position: absolute;
  top: 0;
  margin-top:35px;
  
}
.by{
  border: 1.5px solid #FAFAFA;
  width: 50px;
  border-radius: 3px;
  
}
.top_pts{
  margin-bottom: 6px;
}
.down_pts{
  margin-top: 6px;
}

@media (max-width:690px){
  .left_box{
    
    width: 300px;
    height: 206px;
    
    font-size: 1.1rem;
    
  }
  .right_box{
   
    width: 93px;
    height: 206px;
    display: flex;
    align-items: center;
    font-family: "Manrope";
    font-weight: 600;
    font-size: 1.1rem;
    
    
  }
}

@media (max-width:530px){
  .left_box{
    
    width: 210px;
    height: 161px;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 89px 23px;
  }
  .right_box{
    
    width: 60px;
    height: 161px;
    display: flex;
    align-items: center;
    
    font-size: 0.75rem;
    
    
  }
  .star{
    height:16px ;
    width: 16px;
    position: absolute;
    top: 0;
    margin-top:35px;
    
  }
}