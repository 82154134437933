.radiant-container {
  width: 100%;
  height: auto;
  gap: 80px;
  /* justify-content: center;   */
  /* justify-content: space-between; */
  /* align-items: center  ;   */
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap;  */
  background-color: #202020;
  padding: 150px 70px;
}
.radiant-title {
  color: #efd5ff;
  font-family: Syne;
  font-size: 7.581rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-transform: uppercase;
}
.radiant-paraflex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 48px;
  flex-direction: column;
}
.radiant-flex {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  /* justify-content: center;   */
  /* gap: 85px; */
}
.radiant-para {
  color: #efd5ff;
  font-family: Manrope;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  text-transform: capitalize;
  height: auto;
  width: 678px;
}
.heliocentric-image {
  max-width: 100%;
  height: auto;
}
/*---------------------------responsiveness----------------------------*/
@media (max-width: 1475px) {
  .radiant-title {
    font-size: 6rem;
  }
}
@media (max-width: 1435px) {
  .radiant-para {
    font-size: 1.4rem;
    line-height: 46px;
  }
  .radiant-paraflex {
    gap: 48px;
  }
  .heliocentric-image {
    width: 500px;
    height: 685px;
  }
}
@media (max-width: 1359px) {
  .radiant-title {
    font-size: 5rem;
  }
  .radiant-para {
    font-size: 1.3rem;
    line-height: 40px;
  }
  .radiant-paraflex {
    gap: 48px;
  }
  .heliocentric-image {
    width: 435px;
    height: 610px;
  }
}
@media (max-width: 1270px) {
  .radiant-title {
    font-size: 5rem;
  }
  .radiant-para {
    font-size: 1.2rem;
    line-height: 35px;
    width: 500px;
  }
  .radiant-paraflex {
    gap: 40px;
  }
  .heliocentric-image {
    width: 360px;
    height: 535px;
  }
  .heliocentric-image {
    margin-top: 30px;
  }
}
@media (max-width: 1024px) {
  .radiant-title {
    font-size: 3.5rem;
  }
}
@media (max-width: 1020px) {
  .radiant-para {
    font-size: 1rem;
    line-height: 35px;
    width: 500px;
  }
  .radiant-paraflex {
    gap: 40px;
  }
  .heliocentric-image {
    width: 285px;
    height: 460px;
  }
  .heliocentric-image {
    margin-top: 0px;
  }
}
@media (max-width: 954px) {
  .radiant-title {
    font-size: 3.5rem;
  }
  .radiant-para {
    font-size: 0.975rem;
    line-height: 35px;
    width: 460px;
  }
  .radiant-paraflex {
    gap: 40px;
  }
  .heliocentric-image {
    width: 250px;
    height: 425px;
  }
  .heliocentric-image {
    margin-top: 35px;
  }
}
@media (max-width: 870px) {
  .radiant-title {
    font-size: 3.5rem;
    /* text-align: center; */
  }
  .radiant-para {
    font-size: 0.975rem;
    line-height: 30px;
    width: 430px;
  }
  .radiant-paraflex {
    gap: 20px;
    /* justify-content: center; */
  }
  .heliocentric-image {
    width: 250px;
    height: 425px;
  }
  .heliocentric-image {
    margin-top: 35px;
  }
}
@media (max-width: 836px) {
  .radiant-title {
    font-size: 3.5rem;
    text-align: center;
  }
  .radiant-para {
    font-size: 0.975rem;
    line-height: 30px;
    width: 450px;
  }
  .radiant-paraflex {
    gap: 20px;
    /* justify-content: center; */
  }
  .heliocentric-image {
    width: 250px;
    height: 425px;
  }
  .heliocentric-image {
    margin-bottom: 80px;
  }
  .radiant-flex {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
    justify-content: center;
    /* gap: 85px; */
  }
  .radiant-container {
    gap: 40px;
    padding: 150px 70px;
  }
}
@media (max-width: 756px) {
  .radiant-title {
    font-size: 2.8rem;
    text-align: center;
  }
}
@media (max-width: 632px) {
  .radiant-title {
    font-size: 2.3rem;
    text-align: center;
  }
  .radiant-container {
    /* gap: 40px; */
    padding: 100px 70px;
  }
  .heliocentric-image {
    width: 226px;
    height: 298px;
  }
  .radiant-para {
    font-size: 0.975rem;
    line-height: 30px;
    width: auto;
  }
}
@media (max-width: 632px) {
  .radiant-title {
    font-size: 2rem;
    /* width: 360px; */
    text-align: center;
  }
  .radiant-para {
    width: auto;
  }
}
@media (max-width: 492px) {
  .radiant-title {
    font-size: 1.8rem;
  }
  .radiant-container {
    padding: 60px 16px;
  }
  .heliocentric-image {
    margin-bottom: 50px;
  }
  .radiant-para {
    font-size: 0.875rem;
  }
}
