.makeusdiff-container {
  padding:150px 50px;
  background-color: #202020; 
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.makeusdiff-title {
   color: #efd5ff;
  font-family: Syne;
  font-size: 3.815rem;
  font-style: normal;
  font-weight: 700;
  width: 650px; 
  text-transform: uppercase;
}
.makeusdiff-title-span {
  position: relative;
}

.makeusdiff-title-span::before {
  content: "";
  position: absolute;
  width: 100%; 
  top: -10px; 
  left: -5px; 
  height: 6px;
  background: #efd5ff; 
}

.makeusdiff-subtitle-flex {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /*  max-width: 438px;
  width: auto; */
}
.makeusdiff-subtitle {
  color: #efd5ff;
  font-family: Manrope;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  /* width: 437px; */
  width: auto;
  height: auto;
}

@media  (max-width:1250px) {
  .makeusdiff-subtitle-flex {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    /*  max-width: 438px;
    width: auto; */
  }
  .makeusdiff-title {
    color: #efd5ff;
   font-family: Syne;
   font-size: 2.815rem;
   font-style: normal;
   font-weight: 700;
   width: 500px; 
 }
 .makeusdiff-subtitle {
  color: #efd5ff;
  font-family: Manrope;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 350px;
  height: auto;
}
.makeusdiff-container {
  padding:150px 16px;
  background-color: #202020; 
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
}

@media (max-width: 930px) {
  .makeusdiff-subtitle-flex {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    /*  max-width: 438px;
    width: auto; */
  }
  .makeusdiff-title {
    color: #efd5ff;
   font-family: Syne;
   font-size: 2.815rem;
   font-style: normal;
   font-weight: 700;
   width: 455px; 

 }
}

@media (max-width: 472px) {
  .makeusdiff-container {
    padding:60px 16px;
    background-color: #202020; 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .makeusdiff-subtitle-flex {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 30px;
    /*  max-width: 438px;
    width: auto; */
  }
  
  .makeusdiff-title {
    color: #efd5ff;
   font-family: Syne;
   font-size: 1.563rem;
   font-style: normal;
   font-weight: 700;
   width: 455px; 
 }
 .makeusdiff-subtitle {
  color: #efd5ff;
  font-family: Manrope;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 350px;
  height: auto;
}
.makeusdiff-title-span::before {
  content: "";
  position: absolute;
  width: 100%; 
  top: -10px; 
  left: -5px; 
  height: 2px;
  background: #efd5ff; 
}
}
