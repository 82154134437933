.mob_signup_container
{
    background-image: url(../../logos/mobilebg.jpg);
}
#mobile_eye{
    position: absolute;
     right: 15px;
    top: 36%;
    margin-top: 5px;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    color: gray;
    }