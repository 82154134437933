.brandsYouLove-container {
  background-color: #202020;
  max-width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  /* gap: 102px; */
  align-items: center;
  padding-top: 150px;
}
.mob_container{
  display: none;
}
.brandsYouLove-title {
  color: #efd5ff;
  font-family: Syne;
  font-size: 7.581rem;
  font-style: normal;
  padding-left: 20px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}
.brandsYouLove-flex {
  display: flex;
  gap: 75px;
  padding-top: 100px;
  padding-left: 39px;
  border-radius: 15px 15px 0px 0px;
  background: #fff;
  
  /* height: 1023px; */
  /* width: 1338px; */
}
.brandsYouLove-flex-left {
  display: flex;
  gap: 84px;
  flex-direction: column;
}
.brandsYouLove-mainflex-right {
  display: flex;
  gap: 40px;
}
.brandsYouLove-flex-right {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.brandsYouLove-flexdown-right {
  display: flex;
  justify-content: space-between;
}
.adidasbw {
  height: 724px;
  width: 430px;
  border-radius: 14px;
}
.adidasred {
  width: 698px;
  height: 405px;
  border-radius: 8.853px;
}
.puma {
  width: 270px;
  height: 408px;
  border-radius: 8.853px;
}
.brandsYouLove-words {
  color: #000;
  font-family: Manrope;
  font-size: 79.93px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-transform: capitalize;
}
.brandsYouLove-span {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.brandsYouLove-two {
  width: 307px;
  height: 400px;
  color: #000;
  font-family: Manrope;
  font-size: 4.996rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.vertical-wordings {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.vertical-wordings h3 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: #000;
  font-family: Manrope;
  font-size: 1.953rem;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  text-transform: capitalize;
}

/**----------------*************----------------------**/


/***************************************************************/
@media (max-width:1322px){
  .adidasbw {
    height: 624px;
    width: 330px;
  }
  .adidasred {
    width: 598px;
    height: 345px;
  }
  .puma {
    width: 220px;
    height: 328px;
  }
  .brandsYouLove-two {
    width: 207px;
    height: 300px;
    font-size: 3.996rem;
  }
  .brandsYouLove-words {
    font-size: 4.996rem;
  }
  .brandsYouLove-span {
    font-size: 1.3rem;
  }
  .brandsYouLove-title {
    color: #efd5ff;
    font-family: Syne;
    font-size: 6rem;
    font-style: normal;
    padding-left: 0px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}


@media (max-width:1125px){
  .adidasbw {
    height: 524px;
    width: 300px;
  }
  .adidasred {
    width: 498px;
    height: 305px;
  }
  .puma {
    width: 200px;
    height: 300px;
  }
  .brandsYouLove-two {
    width: 107px;
    height: 200px;
    font-size: 2.8rem;
  }
  .brandsYouLove-words {
    font-size: 2.996rem;
  }
  .brandsYouLove-span {
    font-size: 1rem;
  }
  .vertical-wordings h3 {
    font-size: 1.6rem;
    line-height: 46px;
  }
  .brandsYouLove-title {
    color: #efd5ff;
    font-family: Syne;
    font-size: 5rem;
    font-style: normal;
    padding-left: 0px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}
@media (max-width:1040px){
  .adidasbw {
    height: 500px;
    width: 280px;
  }
  .adidasred {
    width: 478px;
    height: 280px;
  }
  .puma {
    width: 180px;
    height: 280px;
  }
  .brandsYouLove-two {
    width: 107px;
    height: 200px;
    font-size: 2.5rem;
  }
  .brandsYouLove-words {
    font-size: 2.5rem;
  }
  .brandsYouLove-span {
    font-size: 1rem;
  }
  .vertical-wordings h3 {
    font-size: 1.5rem;
    line-height: 46px;
  }
  .brandsYouLove-title {
    color: #efd5ff;
    font-family: Syne;
    font-size: 4rem;
    font-style: normal;
    padding-left: 0px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

@media (max-width:1000px){
  .adidasbw {
    height: 450px;
    width: 230px;
  }
  .adidasred {
    width: 428px;
    height: 230px;
  }
  .puma {
    width: 170px;
    height: 230px;
  }
  .brandsYouLove-two {
    width: 107px;
    height: 200px;
    font-size: 2.3rem;
  }
  .brandsYouLove-words {
    font-size: 2.5rem;
  }
  .brandsYouLove-span {
    font-size: 1rem;
  }
  .vertical-wordings h3 {
    font-size: 1.5rem;
    line-height: 46px;
  }
}
@media (max-width:910px){
  .adidasbw {
    height: 450px;
    width: 230px;
  }
  .adidasred {
    width: 428px;
    height: 230px;
  }
  .puma {
    width: 170px;
    height: 230px;
  }
  .brandsYouLove-two {
    width: 107px;
    height: 200px;
    font-size: 2.3rem;
  }
  .brandsYouLove-words {
    font-size: 2rem;
  }
  .brandsYouLove-span {
    font-size: 0.8rem;
  }
  .vertical-wordings h3 {
    font-size: 1.2rem;
    /* line-height: 46px; */
  }
  .brandsYouLove-flex {
    display: flex;
    gap: 25px;
    padding-top: 100px;
    padding-left: 39px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;
    /* height: 1023px; */
    /* width: 1338px; */
  }
  .brandsYouLove-flex-left {
    display: flex;
    gap: 44px;
    flex-direction: column;
  }
  .brandsYouLove-mainflex-right {
    display: flex;
    gap: 30px;
  }
  .brandsYouLove-flex-right {
    display: flex;
    flex-direction: column;
    gap: 70px;
  }
}

@media (max-width:850px){
  .adidasbw {
    height: 350px;
    width: 190px;
  }
  .adidasred {
    width: 408px;
    height: 230px;
  }
  .puma {
    width: 150px;
    height: 200px;
  }
  .brandsYouLove-two {
    width: 107px;
    height: 200px;
    font-size: 2.3rem;
  }
  .brandsYouLove-words {
    font-size: 2rem;
  }
  .brandsYouLove-span {
    font-size: 0.8rem;
  }
  .vertical-wordings h3 {
    font-size: 1.1rem;
    /* line-height: 46px; */
  }
  .brandsYouLove-flex {
    display: flex;
    gap: 25px;
    padding-top: 100px;
    padding-left: 39px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;
    /* height: 1023px; */
    /* width: 1338px; */
  }
  .brandsYouLove-flex-left {
    display: flex;
    gap: 44px;
    flex-direction: column;
  }
  .brandsYouLove-mainflex-right {
    display: flex;
    gap: 30px;
  }
  .brandsYouLove-flex-right {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
@media (max-width:836px){
  .adidasbw {
    height: 350px;
    width: 190px;
  }
  .adidasred {
    width: 408px;
    height: 230px;
  }
  .puma {
    width: 150px;
    height: 200px;
  }
  .brandsYouLove-two {
    display: none;
  }
  .brandsYouLove-words {
    display: none;
  }
  .brandsYouLove-span {
    display: none;
  }
  .vertical-wordings h3 {
    display: none;
  }
  .brandsYouLove-flex {
    display: block;
    gap: 25px;
    padding-top: 100px;
    padding-left: 39px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;
    /* height: 1023px; */
    /* width: 1338px; */
  }
  .brandsYouLove-flex-left {
    display: flex;
    gap: 44px;
   
    /* flex-direction: column; */
  }
  .brandsYouLove-mainflex-right {
    /* display: flex; */
    gap: 30px;
  }
  .brandsYouLove-flex-right {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}


/* YourComponent.css */

/* Styles for screens with a maximum width of 720px */
@media screen and (max-width: 836px) {
  .brandsYouLove-flex {
    display: none; /* Hide the flex layout */
  }
  .brandsYouLove-container {

    padding-top: 50px;
  }
  
  .mob_container{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 50px;
    padding-left: 10px;
    padding-right:10px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;
    padding-bottom: 50px;
  }
  .mob_downdiv{
  display: flex;
  justify-content: space-between;
  }
  .adidasredmob{
  height:auto ;
  width:auto;
  }
  .adidasbwmob{
    height:500px ;
    width:330px;
  }
  .pumamob{
    height:500px ;
    width:330px;
  }
}
@media screen and (max-width: 810px) {
  
 
  .mob_downdiv{
  display: flex;
  justify-content: space-between;
  }
  .adidasredmob{
  height:350px;
  width:600px;
  }
  .adidasbwmob{
    height:400px ;
    width:290px;
  }
  .pumamob{
    height:400px ;
    width:290px;
  }
}
@media screen and (max-width: 730px) {
  
 
  .mob_downdiv{
  display: flex;
  justify-content: space-between;
  }
  .adidasredmob{
  height:270px;
  width:500px;
  }
  .adidasbwmob{
    height:350px ;
    width:240px;
  }
  .pumamob{
    height:350px ;
    width:240px;
  }

  .brandsYouLove-title {
    color: #efd5ff;
    font-family: Syne;
    font-size: 3rem;
    font-style: normal;
    padding-left: 0px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 630px) {
    
  
  .mob_downdiv{
  display: flex;
  justify-content: space-between;
  }
  .adidasredmob{
    width: 355px;
    height: 200.6px;
  
    
  }
  .adidasbwmob{
    height:222px ;
    width:150px;
  }
  .pumamob{
    height:222px ;
    width:150px;
    
    
  }

  .brandsYouLove-title {
    color: #efd5ff;
    font-family: Syne;
    font-size: 2.5rem;
    font-style: normal;
    padding-left: 0px;
    margin-bottom: 40px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
  }
}

  /* @media screen and (max-width: 630px) {
    
  
    .mob_downdiv{
    display: flex;
    justify-content: space-between;
    }
    .adidasredmob{
      width: 295.75px;
      height: 171.6px;
    
      
    }
    .adidasbwmob{
      height:222px ;
      width:132px;
    }
    .pumamob{
      width: 151px;
      height: 223px;
      
      
    }

    .brandsYouLove-title {
      color: #efd5ff;
      font-family: Syne;
      font-size: 2rem;
      font-style: normal;
      padding-left: 30px;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      text-transform: uppercase;
    }
  } */